import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const uid = ref(null)
  const username = ref(null)
  const perms = ref(null)

  function login(uid_, username_, permissions) {
    uid.value = uid_
    username.value = username_
    perms.value = permissions
  }

  function logout() {
    uid.value = null
    username.value = null
    perms.value = null
  }

  function update_profile(username_) {
    username.value = username_
  }

  return { uid, username, perms, login, logout, update_profile }
})
