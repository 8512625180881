import { createRouter, createWebHistory } from 'vue-router'

// Lazy loading of all the views (also will generate splitted bundle for each
// view: route level code-splitting)
const AboutView = () => import('../views/AboutView.vue')
const AreaUpdate = () => import('../views/AreaUpdate.vue')
const AreasList = () => import('../views/AreaList.vue')
const DgtList = () => import('../views/DgtList.vue')
const HomeView = () => import('../views/HomeView.vue')
const IndicatorDetail = () => import('../views/IndicatorDetail.vue')
const IndicatorDetailPub = () => import('../views/IndicatorDetailPub.vue')
const IndicatorList = () => import('../views/IndicatorList.vue')
const IndicatorUpdate = () => import('../views/IndicatorUpdate.vue')
const ObjetivoList = () => import('../views/ObjetivoList.vue')
const ObjetivoUpdate = () => import('../views/ObjetivoUpdate.vue')
const PasswordRequest = () => import('../components/auth/PasswordRequest.vue')
const PasswordReset = () => import('../components/auth/PasswordReset.vue')
const SerieData = () => import('../views/SerieData.vue')
const SerieList = () => import('../views/SerieList.vue')
const SerieDetail = () => import('../views/SerieDetail.vue')
const SerieUpdate = () => import('../views/SerieUpdate.vue')
const ExportData = () => import('../views/ExportData.vue')
const UserDetail = () => import('../components/auth/UserDetail.vue')
const UserList = () => import('../components/auth/UserList.vue')
const UserLogin = () => import('../components/auth/UserLogin.vue')
const UserLogout = () => import('../components/auth/UserLogout.vue')
const UserProfile = () => import('../components/auth/UserProfile.vue')
const UserUpdate = () => import('../components/auth/UserUpdate.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', component: HomeView },
    { path: '/about', name: 'about', component: AboutView },

    // Areas
    { path: '/areas/', name: 'AreaList', component: AreasList },
    { path: '/areas/create', name: 'AreaCreate', component: AreaUpdate },
    { path: '/areas/:id/update', name: 'AreaUpdate', component: AreaUpdate },

    // Objetivos
    { path: '/objetivos/', name: 'ObjetivoList', component: ObjetivoList },
    {
      path: '/objetivos/create',
      name: 'ObjetivoCreate',
      component: ObjetivoUpdate
    },
    {
      path: '/objetivos/:id/update',
      name: 'ObjetivoUpdate',
      component: ObjetivoUpdate
    },

    // Series
    { path: '/series/', name: 'SerieList', component: SerieList },
    {
      path: '/series/:id/',
      name: 'SerieDetail',
      component: SerieDetail
    },
    {
      path: '/series/:id/update',
      name: 'SerieUpdate',
      component: SerieUpdate
    },
    {
      path: '/series/create',
      name: 'SerieCreate',
      component: SerieUpdate
    },
    {
      path: '/series/:id/data',
      name: 'SerieData',
      component: SerieData
    },

    // Indicadores
    {
      path: '/indicator/',
      name: 'indicator-list',
      component: IndicatorList,
      meta: { title: 'Indicadores' }
    },
    {
      path: '/indicator/:slug/',
      name: 'IndicatorDetail',
      component: IndicatorDetail,
      meta: { title: 'Indicador' }
    },
    {
      path: '/indicator/create',
      name: 'IndicatorCreate',
      component: IndicatorUpdate,
      meta: { title: 'Crear indicador' }
    },
    {
      path: '/indicator/:slug/update',
      name: 'IndicatorUpdate',
      component: IndicatorUpdate,
      meta: { title: 'Modificar indicador' }
    },
    {
      path: '/indicator/:slug/serie/:label/data',
      name: 'IndicatorSerieData',
      component: SerieData,
      meta: { title: 'Datos de la serie' }
    },
    {
      path: '/pub/indicator/:slug/',
      name: 'IndicatorDetailPub',
      component: IndicatorDetailPub,
      meta: { title: 'Indicador' }
    },

    // export data
    {
      path: '/export/',
      name: 'ExportData',
      component: ExportData
    },
    // DGT task
    { path: '/dgt/', name: 'DgtList', component: DgtList },

    // Usuarios
    { path: '/auth/login', name: 'login', component: UserLogin },
    { path: '/auth/login', name: 'logout', component: UserLogout },
    { path: '/auth/user/', name: 'UserList', component: UserList },
    { path: '/auth/user/create', name: 'UserCreate', component: UserUpdate },
    { path: '/auth/profile', name: 'UserProfile', component: UserProfile },
    {
      path: '/auth/password-request',
      name: 'PasswordRequest',
      component: PasswordRequest
    },
    {
      path: '/auth/password-reset/:token/',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/auth/user/:uid/update',
      name: 'UserUpdate',
      component: UserUpdate
    },
    { path: '/auth/user/:uid/', name: 'UserDetail', component: UserDetail }
  ]
})

export default router
