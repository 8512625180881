// CSS resources
import 'primevue/resources/themes/aura-light-cyan/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@/assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueMeta from 'vue-meta'
// import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import FocusTrap from 'primevue/focustrap'
import Ripple from 'primevue/ripple'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'

const pinia = createPinia()
const app = createApp(App)

// Application title
// https://stackoverflow.com/questions/66228340/how-to-use-vue-3-meta-with-vue-js-3/67120044#67120044
// https://github.com/nuxt/vue-meta/tree/next
// https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
// https://stackoverflow.com/questions/66228340/how-to-use-vue-3-meta-with-vue-js-3
//router.afterEach((to, from) => {
//  // Use next tick to handle router history correctly
//  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//  Vue.nextTick(() => {
//    // document.title = to.meta.title || DEFAULT_TITLE;
//    document.title = 'EECAM'
//  })
//})
router.beforeEach((to, _, next) => {
  document.title = to.meta.title ? 'EECAM - ' + to.meta.title : 'EECAM'
  next();
});

app.use(router)
// app.use(VueMeta)
// app.use(createMetaManager())
app.use(pinia)
app.use(PrimeVue, { ripple: true })
app.use(ToastService)
app.use(ConfirmationService)
app.directive('focustrap', FocusTrap)
app.directive('ripple', Ripple)
// To be able to access toast service from outside components
// (useToast() can't be used from outside components, you get
// "inject() can only be used inside setup() or functional components" error.
export const toast = app.config.globalProperties.$toast
app.component('ConfirmPopup', ConfirmPopup)
app.component('Toast', Toast)

// Primevue components not imported and registerd here.
// Using unplugin-vue-components to resolve and import automatically.
// This reduces bundle size of 'index' bundle.

app.mount('#app')
